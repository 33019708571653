import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

import song, {
  deleteSongEpic,
  getSongsEpic,
  postSongEpic,
  postSongWithFileEpic
} from './common/slices/songSlice/songSlice';

export const rootEpic = combineEpics(
  getSongsEpic,
  postSongEpic,
  deleteSongEpic,
  postSongWithFileEpic
);

export const history = createBrowserHistory();

const rootReducer = combineReducers({ router: connectRouter(history), song });

const epicMiddleware = createEpicMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: [routerMiddleware(history), epicMiddleware],
  devTools: true
});

epicMiddleware.run(rootEpic);

export type RootState = ReturnType<typeof rootReducer>;
export default store;
