import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import httpClient from '../../../services/httpClient/httpClient';
import { Song as ISong } from '../../../types/Song';
import toastUtil from '../../../utilities/toastUtil/toastUtil';
import WaveSurfer from '../../../components/WaveSurfer/WaveSurfer';

const Song = (): ReactElement => {
  const { songId } = useParams<{ songId: string }>();

  const [song, setSong] = useState<null | ISong>(null);

  useEffect(() => {
    (async () => {
      try {
        const { data: song } = await httpClient.getSong(songId);
        setSong(song);
      } catch (err) {
        toastUtil.error(err.message);
      }
    })();
  }, []);

  return (
    <div>
      {song && (
        <>
          <h1 className="song-title">{song.title}</h1>
          <WaveSurfer songUrl={song.url} />
        </>
      )}
    </div>
  );
};

export default Song;
