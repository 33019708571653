import React, { useRef, useEffect, useState } from 'react';

import { create, IWaveSurfer } from 'wavesurfer.js';

import './WaveSurfer.scss';

interface Props {
  songUrl: string;
}

const WaveSurfer = ({ songUrl }: Props): React.ReactElement => {
  const wavesurferRef = useRef(null);

  const [instance, setInstance] = useState<IWaveSurfer>();

  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const isPlayingRef = useRef(isPlaying);

  const buttonText = isPlaying ? 'Stop' : 'Play';

  useEffect(() => {
    isPlayingRef.current = isPlaying;
  }, [isPlaying]);

  useEffect(() => {
    if (wavesurferRef.current) {
      const wavesurferInstance = create({
        container: wavesurferRef.current,
        fillParent: true
      });

      setInstance(wavesurferInstance);
    }
  }, []);

  useEffect(() => {
    if (instance) {
      setupEventListeners();
      return () => instance.pause();
    }
  }, [instance]);

  useEffect(() => {
    if (instance) {
      instance.pause();
      setIsPlaying(false);
      instance.load(songUrl);
    }
  }, [songUrl, instance]);

  const setupEventListeners = () => {
    if (instance) {
      instance.on('ready', () => setIsReady(true));
      instance.on('seek', () => {
        if (instance && isPlayingRef.current) {
          instance.play();
        }
      });
    }
  };

  const togglePlay = () => {
    if (instance) {
      if (isPlaying) {
        instance.pause();
        setIsPlaying(false);
      } else {
        instance.play();
        setIsPlaying(true);
      }
    }
  };

  return (
    <div className="wave-surfer-wrapper">
      <div className="wave-surfer" ref={wavesurferRef} />
      <button disabled={!isReady} onClick={togglePlay}>
        {buttonText}
      </button>
    </div>
  );
};

export default WaveSurfer;
