import { toast } from 'react-toastify';

const toastUtil = {
  success: (successMessage: string): void => {
    toast(successMessage, {
      className: 'toast-success-container'
    });
  },
  error: (errorMessage: string): void => {
    toast(errorMessage, {
      className: 'toast-error-container'
    });
  },
  info: (infoMessage: string): void => {
    toast(infoMessage, {
      className: 'toast-info-container'
    });
  }
};

export default toastUtil;
