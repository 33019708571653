import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { from, of, Subject } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  withLatestFrom,
  tap,
  mergeMap,
  mapTo
} from 'rxjs/operators';

import { RootState } from '../../../store';
import httpClient from '../../services/httpClient/httpClient';
import { Song } from '../../types/Song';

const handleProgressEvent = (
  state: RootState,
  { loaded, total }: ProgressEvent
): void => {
  state.song.uploadSongFileStatus.uploadProgress$.next((loaded / total) * 100);
};

export interface Status {
  pending: boolean;
  success$: Subject<string>;
  error$: Subject<string>;
}

export interface UploadSongFilePayload {
  songTitle: string | null;
  file: File | null;
  fileType: string | null;
}
export interface DeleteSongStatus extends Status {
  song_id: string | null;
}
export interface PostSongStatus extends Status {
  song: Partial<Song> | null;
}

export interface UploadSongFileStatus extends Status, UploadSongFilePayload {
  uploadProgress$: Subject<number>;
}

export interface SongSliceState {
  songs: Song[];
  getSongsStatus: Status;
  deleteSongStatus: DeleteSongStatus;
  postSongStatus: PostSongStatus;
  uploadSongFileStatus: UploadSongFileStatus;
}

export const initialState: SongSliceState = {
  songs: [],
  getSongsStatus: {
    pending: false,
    error$: new Subject(),
    success$: new Subject()
  },
  deleteSongStatus: {
    song_id: null,
    pending: false,
    error$: new Subject(),
    success$: new Subject()
  },
  postSongStatus: {
    song: null,
    pending: false,
    error$: new Subject(),
    success$: new Subject()
  },
  uploadSongFileStatus: {
    songTitle: null,
    file: null,
    fileType: null,
    pending: false,
    error$: new Subject(),
    success$: new Subject(),
    uploadProgress$: new Subject()
  }
};

export const reducers = {
  getSongs: (state: SongSliceState): SongSliceState => {
    return {
      ...state,
      getSongsStatus: { ...state.getSongsStatus, pending: true }
    };
  },
  getSongsComplete: (
    state: SongSliceState,
    { payload: songs }: PayloadAction<Song[]>
  ): SongSliceState => {
    return {
      ...state,
      getSongsStatus: { ...state.getSongsStatus, pending: false },
      songs
    };
  },
  postSong: (
    state: SongSliceState,
    { payload }: { payload: Partial<Song> }
  ): SongSliceState => {
    return {
      ...state,
      postSongStatus: {
        ...state.postSongStatus,
        song: payload,
        pending: true
      }
    };
  },
  postSongComplete: (state: SongSliceState): SongSliceState => {
    return {
      ...state,
      postSongStatus: {
        ...state.postSongStatus,
        song: null,
        pending: false
      }
    };
  },
  deleteSong: (
    state: SongSliceState,
    { payload }: { payload: string }
  ): SongSliceState => {
    return {
      ...state,
      deleteSongStatus: {
        ...state.deleteSongStatus,
        song_id: payload,
        pending: true
      }
    };
  },
  deleteSongComplete: (state: SongSliceState): SongSliceState => {
    return {
      ...state,
      deleteSongStatus: {
        ...state.deleteSongStatus,
        song_id: null,
        pending: false
      }
    };
  },
  uploadSongFile: (
    state: SongSliceState,
    { payload }: { payload: UploadSongFilePayload }
  ): SongSliceState => {
    const { songTitle, file, fileType } = payload;
    return {
      ...state,
      uploadSongFileStatus: {
        ...state.uploadSongFileStatus,
        songTitle,
        file,
        fileType,
        pending: true
      }
    };
  },
  uploadSongFileComplete: (state: SongSliceState): SongSliceState => {
    return {
      ...state,
      uploadSongFileStatus: {
        ...state.uploadSongFileStatus,
        songTitle: null,
        file: null,
        fileType: null,
        pending: false
      }
    };
  }
};

const songSlice = createSlice({
  name: 'song',
  initialState,
  reducers
});

// ACTIONS
export const {
  getSongs,
  getSongsComplete,
  postSong,
  postSongComplete,
  deleteSong,
  deleteSongComplete,
  uploadSongFile,
  uploadSongFileComplete
} = songSlice.actions;

// EPICS
export const getSongsEpic: Epic = (
  action$,
  store$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(getSongs.type),
    withLatestFrom(store$),
    switchMap(([, state]) =>
      from(httpClient.getSongs()).pipe(
        switchMap((result) => of(result.data)),
        catchError((err) => {
          state.song.getSongsStatus.error$.next(err.message);
          const { song } = state;
          return of([...song.songs]);
        })
      )
    ),
    map((payload) => ({ type: getSongsComplete.type, payload }))
  );

export const postSongEpic: Epic = (action$, store$) =>
  action$.pipe(
    ofType(postSong.type),
    withLatestFrom(store$),
    switchMap(([action, state]) =>
      from(httpClient.postSong(action.payload)).pipe(
        tap(() => {
          state.song.postSongStatus.success$.next('Song successfully created!');
        }),
        mergeMap(() =>
          of({ type: postSongComplete.type }, { type: getSongs.type })
        ),
        catchError((err) => {
          state.song.postSongStatus.error$.next(err.message);
          return of(err).pipe(mapTo({ type: postSongComplete.type }));
        })
      )
    )
  );

export const deleteSongEpic: Epic = (
  action$,
  store$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(deleteSong.type),
    withLatestFrom(store$),
    switchMap(([action, state]) =>
      from(httpClient.deleteSong(action.payload)).pipe(
        tap(() => {
          state.song.deleteSongStatus.success$.next(
            'Song successfully deleted!'
          );
        }),
        mergeMap(() =>
          of({ type: deleteSongComplete.type }, { type: getSongs.type })
        ),
        catchError((err) => {
          state.song.deleteSongStatus.error$.next(err.message);
          return of(err).pipe(
            mapTo({
              type: deleteSongComplete.type
            })
          );
        })
      )
    )
  );

export const postSongWithFileEpic: Epic = (
  action$,
  store$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(uploadSongFile.type),
    withLatestFrom(store$),
    switchMap(([action, state]) => {
      const { songTitle, file, fileType } = action.payload;
      return from(
        httpClient.getSignedSongUploadUrl(
          file.name.split(' ').join('-'),
          fileType
        )
      ).pipe(
        switchMap(({ data }) => {
          const { fileUrl, signedUploadUrl } = data;
          return from(
            httpClient.uploadFile(
              signedUploadUrl,
              file,
              fileType,
              (progressEvent) => handleProgressEvent(state, progressEvent)
            )
          ).pipe(
            tap(() => {
              state.song.uploadSongFileStatus.success$.next(
                'Song successfully uploaded!'
              );
            }),
            mergeMap(() =>
              of(
                { type: uploadSongFileComplete.type },
                {
                  type: postSong.type,
                  payload: {
                    title: songTitle,
                    url: fileUrl
                  }
                }
              )
            )
          );
        }),
        catchError((err) => {
          state.song.uploadSongFileStatus.error$.next(err.message);
          return of(err).pipe(
            mapTo({
              type: uploadSongFileComplete.type
            })
          );
        })
      );
    })
  );

// SELECTORS
export const selectSongs = (state: RootState): Song[] => state.song.songs;
export const selectGetSongsStatus = (state: RootState): Status =>
  state.song.getSongsStatus;
export const selectDeleteSongStatus = (state: RootState): DeleteSongStatus =>
  state.song.deleteSongStatus;
export const selectPostSongStatus = (state: RootState): PostSongStatus =>
  state.song.postSongStatus;
export const selectUploadSongFileStatus = (
  state: RootState
): UploadSongFileStatus => state.song.uploadSongFileStatus;

export default songSlice.reducer;
