import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import SongsRouter from './common/features/Songs/SongsRouter';
import {
  getSongs,
  selectGetSongsStatus
} from './common/slices/songSlice/songSlice';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect, Route } from 'react-router-dom';
import toastUtil from './common/utilities/toastUtil/toastUtil';

const App = (): ReactElement => {
  const dispatch = useDispatch();

  const { error$: getSongsError$ } = useSelector(selectGetSongsStatus);

  useEffect(() => {
    dispatch(getSongs());
  }, [dispatch]);

  useEffect(() => {
    const subscription = getSongsError$.subscribe(toastUtil.error);
    return () => subscription.unsubscribe();
  }, []);

  return (
    <>
      <Route exact path="/">
        <Redirect to="/songs" />
      </Route>
      <SongsRouter />
      <ToastContainer position="bottom-center" />
    </>
  );
};

export default App;
