import React, { ReactElement } from 'react';

import { Route, Switch } from 'react-router-dom';
import Songs from './Songs';
import Song from './Song/Song';
import AddSongForm from './AddSongForm/AddSongForm';

const SongsRouter = (): ReactElement => (
  <Switch>
    <Route exact path="/songs">
      <AddSongForm />
      <Songs />
    </Route>
    <Route path="/songs/:songId">
      <Song />
    </Route>
  </Switch>
);

export default SongsRouter;
