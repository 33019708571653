import axios, { AxiosResponse } from 'axios';

import { Song } from '../../types/Song';

interface SignedUploadURLResponse {
  signedUploadUrl: string;
  fileUrl: string;
}
interface ResponseShape<T> {
  data: T[];
}

type FormattedResponse<T> = Partial<AxiosResponse & ResponseShape<T>>;

const apiUrlBase = process.env.API_URL as string;
export const songPath = `${apiUrlBase}/song`;

const formatResponse = (response: AxiosResponse) => {
  const { data: responseData } = response;
  return { ...response, data: responseData.data };
};

export default {
  getSongs: async (): Promise<FormattedResponse<Song[]>> => {
    const response = await axios.get<AxiosResponse<Song[]>>(songPath);
    return formatResponse(response);
  },
  getSong: async (songId: string): Promise<FormattedResponse<Song>> => {
    const response = await axios.get<Song>(`${songPath}/${songId}`);
    return formatResponse(response);
  },
  postSong: async (song: Partial<Song>): Promise<FormattedResponse<Song>> => {
    const response = await axios.post<Song>(songPath, song);
    return formatResponse(response);
  },
  deleteSong: async (songId: string): Promise<FormattedResponse<Song>> => {
    const response = await axios.delete<Song>(`${songPath}/${songId}`);
    return formatResponse(response);
  },
  getSignedSongUploadUrl: async (
    filename: string,
    fileType: string
  ): Promise<FormattedResponse<SignedUploadURLResponse>> => {
    const response = await axios.get<Song>(
      `${songPath}/signSongUrl?file-name=${filename}&file-type=${fileType}`
    );
    return formatResponse(response);
  },
  uploadFile: async (
    signedRequestUrl: string,
    file: File,
    fileType: string,
    onUploadProgress: (progressEvent: ProgressEvent) => void = () => null
  ): Promise<AxiosResponse> => {
    return await axios.put(signedRequestUrl, file, {
      headers: {
        'Content-Type': fileType
      },
      onUploadProgress
    });
  }
};
