import React, { ReactElement, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectUploadSongFileStatus,
  uploadSongFile
} from '../../../slices/songSlice/songSlice';

import './AddSongForm.scss';
import toastUtil from '../../../utilities/toastUtil/toastUtil';
import { Line as ProgressBar } from 'rc-progress';

const AddSongForm = (): ReactElement => {
  const dispatch = useDispatch();

  const {
    success$: uploadSongFileSuccess$,
    error$: uploadSongFileError$,
    pending: uploadSongFilePending,
    uploadProgress$
  } = useSelector(selectUploadSongFileStatus);

  const fileUploadRef = useRef<null | HTMLInputElement>(null);
  const [songTitle, setSongTitle] = useState('');
  const [file, setFile] = useState<null | File>(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    const successSub = uploadSongFileSuccess$.subscribe((successMessage) => {
      toastUtil.success(successMessage);
      setFile(null);
      setSongTitle('');
    });
    const errorSub = uploadSongFileError$.subscribe(toastUtil.error);
    const uploadProgressSub = uploadProgress$.subscribe(setUploadProgress);

    return () => {
      successSub.unsubscribe();
      errorSub.unsubscribe();
      uploadProgressSub.unsubscribe();
    };
  }, []);

  const handlePostSong = () => {
    if (file !== null && songTitle !== '') {
      dispatch(
        uploadSongFile({
          songTitle,
          file,
          fileType: file.type
        })
      );
    }
  };

  const isAddDisabled = !file || !songTitle || uploadSongFilePending;

  return (
    <div className="add-song-form-container">
      <div>
        <input
          className="song-name-input"
          placeholder="Enter song name"
          onChange={(e) => setSongTitle(e.target.value)}
          value={songTitle}
        />
        <input
          className="file-upload-input"
          onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
          type="file"
          accept=".mp3"
          ref={fileUploadRef}
          style={{ display: 'none' }}
        />
        <button
          className="file-upload-button"
          onClick={() => {
            if (fileUploadRef.current) {
              fileUploadRef.current.click();
            }
          }}
        >
          Choose file...
        </button>

        <button
          className="add-song-button"
          disabled={isAddDisabled}
          onClick={handlePostSong}
        >
          Add
        </button>
      </div>
      <div>
        <p className="file-name">{file?.name}</p>
      </div>
      <div className="progress-bar-container">
        {uploadSongFilePending && <ProgressBar percent={uploadProgress} />}
      </div>
    </div>
  );
};

export default AddSongForm;
