import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  deleteSong,
  selectDeleteSongStatus,
  selectGetSongsStatus,
  selectSongs
} from '../../slices/songSlice/songSlice';
import toastUtil from '../../utilities/toastUtil/toastUtil';

import './Songs.scss';

const Songs = (): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pending: getSongsPending } = useSelector(selectGetSongsStatus);
  const {
    error$: deleteSongError$,
    success$: deleteSongSuccess$,
    pending: deleteSongPending,
    song_id
  } = useSelector(selectDeleteSongStatus);

  const songs = useSelector(selectSongs);

  useEffect(() => {
    const errorSub = deleteSongError$.subscribe(toastUtil.error);
    const successSub = deleteSongSuccess$.subscribe(toastUtil.success);
    return () => {
      errorSub.unsubscribe();
      successSub.unsubscribe();
    };
  }, []);

  const handleDeleteSong = (songId: string) => {
    dispatch(deleteSong(songId));
  };

  return (
    <>
      {getSongsPending && <div className="loading-container">Loading...</div>}
      {!getSongsPending &&
        songs.map((song) => (
          <div className="song-container" key={song._id}>
            <div
              className="song"
              onClick={() => history.push(`/songs/${song._id}`)}
            >
              {song.title}
            </div>
            <button
              onClick={() => handleDeleteSong(song._id)}
              className="delete-button"
              disabled={deleteSongPending && song_id === song._id}
            >
              X
            </button>
          </div>
        ))}
    </>
  );
};

export default Songs;
